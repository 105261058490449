<template>
  <div class="container">
    <table-page dataName="marketers"
                :search="search"
                ref="table"
                @on-success="fetchSuccess"
                :request="fetchEmployees">
      <template slot="button">
        <el-button type="primary"
                   class="mr-15"
                   @click="$refs.form.showModel()">新增员工</el-button>
        <FileExport :params="exportParams">导出员工数据</FileExport>
      </template>
      <el-table-column prop="marketer_id"
                       label="员工id">
      </el-table-column>
      <el-table-column prop="name"
                       label="姓名">
      </el-table-column>
      <el-table-column prop="tel"
                       show-overflow-tooltip
                       label="手机号码">
      </el-table-column>
      <el-table-column prop="merchant_total"
                       show-overflow-tooltip
                       label="关联商户数">
      </el-table-column>
      <el-table-column prop="city"
                       show-overflow-tooltip
                       label="地市">
      </el-table-column>
      <el-table-column prop="district"
                       show-overflow-tooltip
                       label="区县">
      </el-table-column>
      <el-table-column prop="net"
                       show-overflow-tooltip
                       label="网点">
      </el-table-column>
      <el-table-column prop="lasted_login_at"
                       show-overflow-tooltip
                       label="最后登录时间">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary"
                     icon="el-icon-edit"
                     size="small"
                     @click="$refs.form.showModel(scope.row)"
                     circle></el-button>
          <el-button type="danger"
                     size="small"
                     icon="el-icon-delete"
                     @click="delStore(scope.row)"
                     circle></el-button>
        </template>
      </el-table-column>
    </table-page>
    <SetEmployee ref="form"
                 @on-success="$refs.table.fetch()" />
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import SetEmployee from './components/SetEmployee'
import FileExport from '@/components/FileExport'
import { fetchEmployees, delStore } from '@/api/system'
export default {
  name: 'Employees',
  data () {
    return {
      exportParams: null,
      fetchEmployees,
      search: [{ type: 'area' }, {
        type: 'select',
        key: 'search_type',
        name: '搜索类型',
        value: '',
        list: [{
          value: 'NAME',
          label: '姓名'
        }, {
          value: 'TEL',
          label: '手机号码'
        }, {
          value: 'NET',
          label: '网点'
        }]
      }, {
        type: 'input',
        value: '',
        key: 'search_keyword',
        placeholder: '请输入关键字'
      }],
      tabList: [{
        value: '',
        label: '全部'
      }, {
        value: 'NORMAL',
        label: '已认证'
      }, {
        value: 'PENDING',
        label: '未认证'
      }, {
        value: 'DENIED',
        label: '认证失败'
      }]
    }
  },
  components: {
    TablePage,
    SetEmployee,
    FileExport
  },
  methods: {
    exportEmployeeData () {
      window.open(this.exportUrl, '_blank')
    },
    delStore (e) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await delStore({
          user_ids: [e.id]
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      }).catch(() => {

      })
    },
    fetchSuccess (e) {
      this.exportParams = e.export
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
</style>
